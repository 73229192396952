import store from "@/store/store";
import { LabelType } from "@/models/Enums";

export default [
  {
    path: "/rental",
    name: "rental",
    component: () => import("@/views/rental/default.vue"),
    beforeEnter(routeTo, routeFrom, next) {

      switch (routeTo.name) {
        case "rental-fleet-object-form":
        case "rental-fleet-object_item":
          store.dispatch("user/setLabelType", LabelType.Rental);
          break;
        default:
          store.dispatch("user/setLabelType", LabelType.Unknown);
          break;
      }
      next();
    },
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "rent",
        name: "rental-rent",
        component: () => import("@/views/rental/rent.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rent/:id",
        name: "rental-form",
        component: () => import("@/views/rental/rent-form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "timeline",
        name: "rental-timeline",
        component: () => import("@/views/rental/timeline.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "avor",
        name: "rental-avor",
        component: () => import("@/views/rental/avor-list.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "overdrawn",
        name: "rental-overdrawn",
        component: () => import("@/views/rental/overdrawn-list.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object_item",
        name: "rental-fleet-object_item",
        component: () => import("@/views/rental/fleet/object-item.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object_item/:id",
        name: "rental-fleet-object_item-detail",
        component: () => import("@/views/rental/fleet/object-item-detail.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object",
        name: "rental-fleet-object",
        component: () => import("@/views/rental/fleet/object.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object/:id",
        name: "rental-fleet-object-form",
        component: () => import("@/views/rental/fleet/object-form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object/sell/:id?",
        name: "rental-fleet-object-sell",
        component: () => import("@/views/rental/fleet/object-sell-extend.vue"),
        meta: {
          authRequired: true,
          type: 'sell'
        },
      },{
        path: "rental-fleet/object/order",
        name: "rental-fleet-object-order",
        component: () => import("@/views/rental/fleet/object-order.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-fleet/object/extend/:rentId",
        name: "rental-fleet-object-extend",
        component: () => import("@/views/rental/fleet/object-sell-extend.vue"),
        meta: {
          authRequired: true,
          type: 'extend'
        },
      },{
        path: "rental-management/attribute/",
        name: "rental-management-attribute",
        component: () => import("@/views/rental/management/attribute.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-management/attribute/:id",
        name: "rental-management-attribute-form",
        component: () => import("@/views/rental/management/attribute-form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-management/attribute-template/",
        name: "rental-management-attribute-template",
        component: () => import("@/views/rental/management/attribute-template.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-management/attribute-template/:id",
        name: "rental-management-attribute-template-form",
        component: () => import("@/views/rental/management/attribute-template-form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-management/pricing",
        name: "rental-management-pricing",
        component: () => import("@/views/rental/management/pricing.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "rental-management/settings",
        name: "rental-management-settings",
        component: () => import("@/views/rental/management/settings.vue"),
        meta: {
          authRequired: true,
        },
      },
    ]
  }
]

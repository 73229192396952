const getDefaultState = () => {
  return {
    openModal: null,
  };
};

export const state = getDefaultState();

export const getters = {};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },
  openModal({ commit }, { terId, modal }) {
    commit("SET_OPEN_MODAL", {modal: modal, id: terId});
  },
  closeModal({ commit }) {
    commit("SET_OPEN_MODAL", null);
  }
};

export const mutations = {
  SET_OPEN_MODAL(state, items) {
    state.openModal = items;
  }
};

import store from "@/store/store";
import { LabelType } from "@/models/Enums";
import subscriptionManagementRoutes from "./subscription-management";
import rentalRoutes from "./rental";
import longtermRentalRoutes from "./longtermrental";
import reportingRoutes from "./reporting";

export default [
  {
    path: "/",
    component: () => import("@/components/layouts/vertical"),
    beforeEnter(routeTo, routeFrom, next) {
      // Restore the LabelType
      store.dispatch("user/setLabelType", LabelType.Unknown);

      store.dispatch("auth/validate").then((res) => {
        if (res) {
          next();
        } else {
          next({ name: "logout" });
        }
      });
    },
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        beforeEnter(routeTo, routeFrom, next) {
          // Show the cycle-home
          next({ path: "/home" });
        },
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/org/:men_link",
        component: () => import("@/views/old"),
        beforeEnter(routeTo, routeFrom, next) {
          next();
        },
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/onboarding",
        component: () => import("@/views/onboarding/form.vue"),
      },
      {
        path: "/org/:men_link/:men_tool",
        component: () => import("@/views/old"),
        beforeEnter(routeTo, routeFrom, next) {
          switch (routeTo.path) {
            case "/org/adressen/contact":
              store.dispatch("user/setLabelType", LabelType.Contact);
              break;
            case "/org/adressen/lieferanten":
              store.dispatch("user/setLabelType", LabelType.Distributor);
              break;
            case "/org/lager/artikelstamm":
              store.dispatch("user/setLabelType", LabelType.Article);
              break;
            case "/org/lager/fahrzeuglager":
              store.dispatch("user/setLabelType", LabelType.Vehicle);
              break;
            case "/org/lager/fahrzeuge_einbuchen":
              store.dispatch("user/setLabelType", LabelType.Vehicle);
              break;
            case "/org/lager/order":
              store.dispatch("user/setLabelType", LabelType.Order);
              break;
            default:
              store.dispatch("user/setLabelType", LabelType.Unknown);
              break;
          }
          next();
        },
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/setting/changelog",
        name: "changelog",
        component: () => import("@/views/settings/changelog.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/personalshopping",
        name: "personalshopping-settings",
        component: () => import("@/views/personalshopping/settings.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/personalshopping",
        name: "personalshopping-overview",
        component: () => import("@/views/personalshopping/overview.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/personalshopping/timeline",
        name: "personalshopping-timeline",
        component: () => import("@/views/personalshopping/timeline.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/personalshopping/:id",
        name: "personalshopping-appointment",
        component: () => import("@/views/personalshopping/form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/office/charging",
        name: "office-charging",
        component: () => import("@/views/office/charging/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/office/charging/:id",
        name: "office-chargingedit",
        component: () => import("@/views/office/charging/form.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/absence_types",
        name: "tt-absences",
        component: () =>
          import("@/views/time-tracking/management/absencetype.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/tasks",
        name: "tt-tasks",
        component: () => import("@/views/time-tracking/management/tasks.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/public_holidays",
        name: "tt-public-holidays",
        component: () =>
          import("@/views/time-tracking/management/public-holidays.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/employment_profiles",
        name: "tt-employment-profiles",
        component: () =>
          import("@/views/time-tracking/management/employment-profiles.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/users",
        name: "tt-users",
        component: () => import("@/views/time-tracking/management/users.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/annual-transfer",
        name: "tt-annual-transfer",
        component: () =>
          import("@/views/time-tracking/management/annual-transfer.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/monthly_approvals",
        name: "tt-monthly-approvals",
        component: () => import("@/views/time-tracking/reports/monthly-approvals.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/monthly_approvals/:id/:date",
        name: "tt-monthly-approvals-detail",
        component: () => import("@/views/time-tracking/reports/monthly-approvals-detail.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/export",
        name: "tt-export",
        component: () => import("@/views/time-tracking/management/export.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/times",
        name: "tt-times",
        component: () => import("@/views/time-tracking/times.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/absence/absence_requests",
        name: "tt-absence-requests",
        component: () =>
          import("@/views/time-tracking/absence/absence-requests.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/absence/staff",
        name: "tt-absence-staff",
        component: () => import("@/views/time-tracking/absence/staff.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/absence/personal",
        name: "tt-absence-personal",
        component: () => import("@/views/time-tracking/absence/personal.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/absence",
        name: "tt-reports-absence",
        component: () => import("@/views/time-tracking/reports/absence.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/formconfig",
        name: "test-form",
        component: () => import("@/views/FormTest.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/work",
        name: "tt-reports-work",
        component: () => import("@/views/time-tracking/reports/work.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/balance",
        name: "tt-reports-balance",
        component: () => import("@/views/time-tracking/reports/balance.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/myworkingtime",
        name: "tt-reports-myworkingtime",
        component: () =>
          import("@/views/time-tracking/reports/myworkingtime.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/overview",
        name: "tt-reports-overview",
        component: () => import("@/views/time-tracking/reports/overview.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/reports/timeentries",
        name: "tt-reports-timeentries",
        component: () =>
          import("@/views/time-tracking/reports/timeentries.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/hourlybalance",
        name: "tt-reports-balanceadjustments-hourlybalance",
        component: () =>
          import("@/views/time-tracking/management/balanceadjustments/hourlybalance.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/timetracking/management/holidaybalance",
        name: "tt-reports-balanceadjustments-holidaybalance",
        component: () =>
          import("@/views/time-tracking/management/balanceadjustments/holidaybalance.vue"),
        meta: {
          authRequired: true
        },
      },
      {
        path: "/timetracking/team",
        name: "tt-team",
        component: () => import("@/views/time-tracking/team.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/lager/spg_search",
        name: "spg-search",
        component: () => import("@/views/storage/spg-article-search.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/billingtype",
        name: "settings-billingtype",
        component: () => import("@/views/settings/billingtype/overview.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: '/settings/billingtype/:id',
        name: "settings-billingtype-edit",
        component: () => import("@/views/settings/billingtype/form.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/vehicletype",
        name: "settings-vehicletype",
        component: () => import("@/views/settings/vehicletype/overview.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: '/settings/vehicletype/:id',
        name: "settings-vehicletype-edit",
        component: () => import("@/views/settings/vehicletype/form.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/question",
        name: "settings-question",
        component: () => import("@/views/settings/agenda/question/overview.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: '/settings/question/:id',
        name: "settings-question-edit",
        component: () => import("@/views/settings/agenda/question/form.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/companydata",
        name: "settings-companydata",
        component: () => import("@/views/settings/companydata.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/usr_settings/profile",
        name: "user-profile",
        component: () => import("@/views/user/profile.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/settings/mail",
        name: "settings-mail",
        component: () => import("@/views/settings/mail.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/settings/accounting",
        name: "settings-accounting",
        component: () => import("@/views/settings/accounting.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "/settings/vat",
        name: "settings-vat",
        component: () => import("@/views/settings/vat-management/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: '/settings/vat/:id',
        name: "settings-vat-edit",
        component: () => import("@/views/settings/vat-management/form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: '/settings/openinghour',
        name: "settings-openinghour",
        component: () => import("@/views/settings/openinghour/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: '/settings/openinghour/:id',
        name: "settings-openinghour-edit",
        component: () => import("@/views/settings/openinghour/form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: 'setting/checklist',
        name: "checklist",
        component: () => import("@/views/system/checklist.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: 'settings/permissions',
        name: "permissions",
        component: () => import("@/views/settings/permissions.vue"),
        meta: {
          authRequired: true,
        },
      },
      ...subscriptionManagementRoutes,
      ...rentalRoutes,
      ...longtermRentalRoutes,
      ...reportingRoutes,
    ],
  },
  {
    path: "/wiki",
    name: "wiki",
    component: () => import("@/components/layouts/vertical-wiki"),
    beforeEnter(routeTo, routeFrom, next) {
      // Restore the LabelType
      store.dispatch("user/setLabelType", LabelType.Unknown);

      next();
    },
    meta: {
      authRequired: true,
      title: "Wiki",
    },
    children: [
      {
        path: "/wiki",
        name: "wiki-index",
        component: () => import("@/views/wiki/index.vue"),
        meta: {
          authRequired: true,
          title: "Wiki",
        },
      },
      {
        path: "/wiki/detail/:page_id/:nice_path?",
        name: "wiki-deatil",
        component: () => import("@/views/wiki/detail.vue"),
        meta: {
          authRequired: true,
          title: "Wiki",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/login"),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      store.dispatch("auth/validate").then((res) => {
        if (res) {
          next({ name: "Home" });
        } else {
          next();
        }
      });
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("auth/logOut");

      store.dispatch("auth/reset");
      store.dispatch("company/reset");
      store.dispatch("language/reset");
      store.dispatch("layout/reset");
      store.dispatch("navigation/reset");
      store.dispatch("user/reset");
      next({ name: "login" });
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("@/views/error/404"),
  },
];

import api from "@/config/Api";
import _ from 'lodash';
import router from '@/router/index.js';

const getDefaultState = () => {
  return {
    navigationSidebar: [],
    navigationWiki: [],
    navigationTopbar: [],
    sidebarIsExtended: true,
    collapseSidebar: 0,
    hasRightbar: false,
    openUrl: "",
    openUrlTrigger: 0,
    routerKey: 0,
    iframeLoading: false,
    pageTitle: ''
  };
};

export const state = getDefaultState();

export const getters = {
  findNavigation(state) {
    return (link, tool, path) => {
      let nav = null;
      state.navigationSidebar.concat(state.navigationTopbar).forEach(item => {
        if (item.link == link || path == `/${item.link}/`) {
          nav = item;
        }
        item.childs.forEach(child => {
          if (child.link == tool && child.parentLink == link || path == `/${child.parentLink}/${child.link}`) {
            nav = {...child};
            nav.parentMenKey = item.menkey
          }
          if(child.childs.length > 0){
            child.childs.forEach(childChild => {
              if (childChild.link == tool && childChild.parentLink == link || path == `/${child.parentLink}/${childChild.parentLink}/${childChild.link}`) {
                nav = {...childChild};
                nav.parentMenKey = item.menkey
              }
            });
          }
        });
      });
      return nav;
    };
  }
};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  updateRouterKey({ commit }) {
    commit("UPDATE_ROUTER_KEY");
  },

  setIframeLoading({ commit }, isLoding) {
    commit("UPDATE_IFRAME_LOADING", isLoding);
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkNavigation( {commit}, routePath) {
    return api
      .get("/menu/navigation/check?path=" + decodeURIComponent(routePath))
      .then(res => {
        if (res && res.data && res.data.data) {
          return res.data.data;
        }
        return false;
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  fetchNavigation({ commit }) {
    return api
      .get("/menu/navigation/list")
      .then(res => {
        if (res.data.data) {
          if (state.navigationSidebar !== res.data.data.sidebar) {
            commit("SET_SIDEBAR", res.data.data.sidebar);
          }
          if (
            res.data.data &&
            state.navigationTopbar !== res.data.data.topbar
          ) {
            commit("SET_TOPBAR", res.data.data.topbar);
          }
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  fetchWiki({ commit }) {
    return api
      .get("/menu/navigation/wikisidebar")
      .then(res => {
        if (res.data.data && state.navigationWiki !== res.data.data) {
          commit("SET_WIKI_SIDEBAR", res.data.data);
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  fetchSidebar({ commit }) {
    return api
      .get("/menu/navigation/sidebar")
      .then(res => {
        if (res.data.data && state.navigationSidebar !== res.data.data) {
          commit("SET_SIDEBAR", res.data.data);
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  fetchTopbar({ commit }) {
    return api
      .get("/menu/navigation/topbar")
      .then(res => {
        if (res.data.data && state.navigationTopbar !== res.data.data) {
          commit("SET_TOPBAR", res.data.data);
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  toggleSidebarExtended({ commit, state }) {
    commit("SET_SIDEBAR_EXTENDED", !state.sidebarIsExtended);
  },

  collapseSidebar({ commit, state }) {
    if (state.sidebarIsExtended) {
      commit("UPDATE_COLLAPSE_SIDEBAR");
    }
  },

  setSidebarExtended({ commit }, extended) {
    commit("SET_SIDEBAR_EXTENDED", extended);
  },

  openUrl({ commit, dispatch }, info) {
    commit("SET_OPEN_URL", { url: `/org/${info.men_link}/${info.men_tool}`, replace: true });
    dispatch("user/refreshCounters", {}, { root: true });
  },

  openNewUrl({ commit, dispatch }, info) {
    if(info.men_link && info.men_tool){
      router.push(`/org/${info.men_link}/${info.men_tool}`)
      commit("SET_OPEN_URL", { url: `/org/${info.men_link}/${info.men_tool}` });
    }else{
      router.push(`/home`)
      commit("SET_OPEN_URL", { url: `/home` });
    }
    dispatch("user/refreshCounters", {}, { root: true });
  },

  openRouterUrl({ commit, dispatch }, to) {
    commit("SET_OPEN_URL", { url: to });
    dispatch("user/refreshCounters", {}, { root: true });
  },

  updatePageTitle({ commit, getters }, { route, i18n }) {
    var pageTitle = '';
    if (route && route.params) {
      let path = route.path;
      if(!_.isEmpty(route.params)){
        let pathSplit = route.path.split('/').filter((el) => el != '')
        for (const [key, value] of Object.entries(route.params)) {
          if(key != 'men_link' && key != 'men_tool' && pathSplit.includes(value)){
            pathSplit.splice(pathSplit.findIndex((item) => item == value), 1)
          }
        }
        path = '/' + pathSplit.join('/');
      }

      var nav = getters.findNavigation(
        route.params.men_link,
        route.params.men_tool,
        path
      );
    }
    if (nav) {
      if (nav.menkey) {
        let additional = '';
        if(nav.parentMenKey){
          additional +=  ' | ' + i18n.global.t(nav.parentMenKey)
        }
        if(nav.grandParentMenKey){
          additional +=  ' | ' + i18n.global.t(nav.grandParentMenKey)
        }
        pageTitle = i18n.global.t(nav.menkey) + additional;
      } else {
        pageTitle = nav.name;
      }
    } else {
      // Use meta if set
      if (route.meta.title) {
        pageTitle = route.meta.title;
      }
    }
    commit("UPDATE_PAGE_TITLE", pageTitle);
  },
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  UPDATE_IFRAME_LOADING(state, isLoading) {
    state.iframeLoading = isLoading;
  },

  SET_WIKI_SIDEBAR(state, navigationWiki) {
    state.navigationWiki = navigationWiki;
  },

  SET_SIDEBAR(state, navigationSidebar) {
    state.navigationSidebar = navigationSidebar;
  },

  SET_TOPBAR(state, navigationTopbar) {
    state.navigationTopbar = navigationTopbar;
  },

  SET_SIDEBAR_EXTENDED(state, isExtended) {
    state.sidebarIsExtended = isExtended;
  },

  UPDATE_COLLAPSE_SIDEBAR(state) {
    state.collapseSidebar++;
  },

  UPDATE_ROUTER_KEY(state) {
    state.routerKey++;
  },

  SET_OPEN_URL(state, { url, replace }) {
    state.openUrl = url;
    state.openUrlTrigger ++;
    if (replace) {
      history.replaceState({}, "", url);
    }
  },

  UPDATE_PAGE_TITLE(state, title) {
    state.pageTitle = title;
  },
};

import api from "@/config/Api";

const getDefaultState = () => {
  return {
    labels: {
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null
    },
    currentLabelType: null,
    labelRequestInProgress: false,
    messageCount: 0,
    changelogCount: 0,
    overdueCount: 0,
    iotStatus: 0,
    directMessagesRequestInProgress: false,
    directMessages: null
  };
};

export const state = getDefaultState();

export const getters = {
  labelsForCurrentLabelType(state) {
    return state.labels[state.currentLabelType];
  }
};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  findDirectMessages({ commit, state }) {
    commit("SET_DIRECT_MESSAGES_REQUEST_ACTIVE", true);
    return api
      .get(`/user/message/directMessages`)
      .then(res => {
        let directMessages = res.data.data ?? [];
        commit("SET_DIRECT_MESSAGES", { directMessages });
        commit("SET_COUNTER", {
          messages: directMessages.length,
          changelog: state.changelogCount,
          overdue: state.overdueCount,
          iotStatus: state.iotStatus
        });
      })
      .catch(e => {
        if (console) console.error(e);
      })
      .finally(() => {
        commit("SET_DIRECT_MESSAGES_REQUEST_ACTIVE", false);
      });
  },

  deleteDirectMessages({ commit, dispatch }) {
    commit("SET_DIRECT_MESSAGES_REQUEST_ACTIVE", true);
    return api
      .delete(`/user/message/directMessages`)
      .then(res => {
        commit("SET_DIRECT_MESSAGES_REQUEST_ACTIVE", false);
        if (res.data.data == true) {
          dispatch("findDirectMessages");
        }
      })
      .catch(e => {
        commit("SET_DIRECT_MESSAGES_REQUEST_ACTIVE", false);
        if (console) console.error(e);
      });
  },

  refreshCounters({ commit }) {
    return api
      .get(`/user/message/counters`)
      .then(res => {
        commit("SET_COUNTER", {
          messages: res.data.data.messages,
          changelog: res.data.data.changelog,
          overdue: res.data.data.overdue,
          iotStatus: res.data.data.iotStatus
        });
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  setLabelType({ state, commit, dispatch }, newLabelType) {
    if (newLabelType != state.currentLabelType) {
      commit("SET_CURRENT_LABEL_TYPE", newLabelType);
      // Load labels, if empty
      if (newLabelType > 0 && !state.labels[newLabelType]) {
        dispatch("refreshLabels", {
          labelType: newLabelType
        });
      }
    }
  },

  addLabel({ commit, dispatch }, { labelType, foreignKey }) {
    commit("SET_LABEL_REQUEST_ACTIVE", true);
    return api
      .post("/user/label/item", {
        labelType,
        foreignKey
      })
      .then(res => {
        if (res.data.data == true) {
          dispatch("refreshLabels", {
            labelType
          });
        }
      })
      .catch(e => {
        if (console) console.error(e);
      })
      .finally(() => {
        commit("SET_LABEL_REQUEST_ACTIVE", false);
      });
  },

  deleteLabel({ commit }, { labelType, id }) {
    commit("SET_LABEL_REQUEST_ACTIVE", true);
    return api
      .delete(`/user/label/item/${id}?labelType=${labelType}`)
      .then(res => {
        if (res.data.data == true) {
          if (id > 0) {
            commit("REMOVE_LABEL", {
              labelType,
              id
            });
          } else {
            commit("SET_LABELS", {
              labelType,
              items: []
            });
          }
        }
      })
      .catch(e => {
        if (console) console.error(e);
      })
      .finally(() => {
        commit("SET_LABEL_REQUEST_ACTIVE", false);
      });
  },

  refreshLabels({ commit }, { labelType }) {
    commit("SET_LABEL_REQUEST_ACTIVE", true);
    return api
      .get(`/user/label/list/${labelType}/`)
      .then(res => {
        commit("SET_LABELS", {
          labelType,
          items: res.data.data ?? []
        });
      })
      .catch(e => {
        if (console) console.error(e);
      })
      .finally(() => {
        commit("SET_LABEL_REQUEST_ACTIVE", false);
      });
  }
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  SET_DIRECT_MESSAGES(state, { directMessages }) {
    state.directMessages = directMessages;
  },

  SET_COUNTER(state, { messages, changelog, overdue, iotStatus }) {
    state.messageCount = messages;
    state.changelogCount = changelog;
    state.overdueCount = overdue;
    state.iotStatus = iotStatus;
  },

  SET_LABEL_REQUEST_ACTIVE(state, inProgress) {
    state.labelRequestInProgress = inProgress;
  },

  SET_DIRECT_MESSAGES_REQUEST_ACTIVE(state, inProgress) {
    state.directMessagesRequestInProgress = inProgress;
  },

  SET_CURRENT_LABEL_TYPE(state, newLabelType) {
    state.currentLabelType = newLabelType;
  },

  SET_LABELS(state, { labelType, items }) {
    state.labels[labelType] = items;
    if (labelType == 3) {
      state.labels[5] = items;
    } else if (labelType == 5) {
      state.labels[3] = items;
    }
  },

  REMOVE_LABEL(state, { labelType, id }) {
    var filtered = state.labels[labelType].filter(item => {
      return item.id != id;
    });
    state.labels[labelType] = filtered;
    if (labelType == 3) {
      state.labels[5] = filtered;
    } else if (labelType == 5) {
      state.labels[3] = filtered;
    }
  }
};

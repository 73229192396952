export default [
  {
    // path: "/subscription-management",
    path: "/subscriptions",
    name: "subscriptions",
    component: () => import("@/views/subscription-management/default.vue"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "overview",
        name: "subscriptions-subscriptions",
        component: () => import("@/views/subscription-management/subscriptions/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "overview/:id",
        name: "subscriptions-subscriptions-form",
        component: () => import("@/views/subscription-management/subscriptions/form.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "types",
        name: "subscriptions-types",
        component: () => import("@/views/subscription-management/types/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: "types/:id",
        name: "subscriptions-types-form",
        component: () => import("@/views/subscription-management/types/form.vue"),
        meta: {
          authRequired: true,
        },
      },
    ]
  }
]

export default [
  {
    path: "/reporting",
    name: "reporting",
    component: () => import("@/views/reporting/default.vue"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "workshop/mechanics",
        name: "mechanics",
        component: () => import("@/views/reporting/workshop/mechanic-utilization.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "workshop/utilization",
        name: "utilization",
        component: () => import("@/views/reporting/workshop/utilization.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "revenue-reports/revenue-statistic",
        name: "revenue-statistic",
        component: () => import("@/views/reporting/revenue/revenue-statistics.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "revenue-reports/warranty",
        name: "warranty",
        component: () => import("@/views/reporting/revenue/warranty.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "revenue-reports/sale-statistic",
        name: "sale-statistic",
        component: () => import("@/views/reporting/revenue/sale-statistic.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "revenue-reports/customer-revenue",
        name: "customer-revenue",
        component: () => import("@/views/reporting/revenue/customer.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "modulreport/rental",
        name: "rental-reporting",
        component: () => import("@/views/reporting/modulreport/rental.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "inventorymngt/inventory",
        name: "inventory-reporting",
        component: () => import("@/views/reporting/inventorymngt/inventory.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "inventorymngt/purchase",
        name: "purchase-reporting",
        component: () => import("@/views/reporting/inventorymngt/purchase.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "inventorymngt/goodsmovement",
        name: "goodsmovement-reporting",
        component: () => import("@/views/reporting/inventorymngt/goodsmovement.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "inventorymngt/stockturnover",
        name: "stockturnover-reporting",
        component: () => import("@/views/reporting/inventorymngt/stockturnover.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "reportmail",
        name: "reportmail",
        component: () => import("@/views/reporting/mail-report.vue"),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
];

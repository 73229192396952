export enum ErrorLevel {
  Unknown = 0,
  Neutral = 1,
  Success = 2,
  Warning = 3,
  Error = 4,
}

export enum LabelType {
  Unknown = 0,
  Contact = 1,
  Distributor = 2,
  Article = 3,
  Vehicle = 4,
  Order = 5,
  Rental = 6,
}

export enum FileType {
  Unknown = 0,
  Temp = 1,
  Appointment = 2,
  Bike = 3,
  Contact = 4,
  Event = 5,
  Journal = 6,
  Leasing = 7,
  LeasingGen = 8,
  Logo = 9,
  Retour = 10,
  Supplier = 11,
  Ticket = 12,
  ORBQuestion = 13,
  Checklist = 14,
  Rent = 15,
  LongTermRent = 16,
  OpeninghourRange = 17,
}

export enum DropdownType {
  Unknown = 0,
  Language = 1,
  Salutation = 2,
  ContactType = 3,
  ContactRelationship = 4,
  ContactCorrespondence = 5,
  Country = 6,
  Color = 7,
  Icon = 8,
  Supplier = 9,
  AccountPlan = 10,
  KindOfBilling = 11,
  Currency = 12,
  Costcenter = 13,
  Vatrate = 14,
  SysCountry = 15,
  Overbooking = 16,
  OnlineshopAvailability = 17,
  SmtpPorts = 18,
  AccountingVatRates = 19,
  ProductGroups = 20,
  VatAccounts = 21,
  SubscriptionTypeType = 22,
  DepotArticle = 23,
  DurationUnits = 24,
  SubscriptionAbo = 25,
  SubscriptionRent = 26,
  AgendaQuestionType = 27,
  AgendaBikeFields = 28,
  Users = 30,
  PersonalshoppingStatusDropdown = 31,
  RentalObjectType = 32,
  RentalPriceruleset = 33,
  RentalPriceruletype = 34,
  RentalPriceUnit = 35,
  RentalPricename = 36,
  RentalPricegroup = 37,
  RentalPricetemplate = 38,
  RentalPricetemplatePosition = 39,
  ArticleUnit = 40,
  RentalSettingCreateBill = 41,
  RentalSettingPricegroup = 42,
  RentalSettingOpeningHour = 43,
  JiraProjects = 44,
  JiraTaskType = 45,
  JiraTaskClearingStatus = 47,
  NavigationAuthorisation = 48,
  CompanyLocation = 49,
  SysLanguage = 50,
  LongTermBruttoIncome = 51,
  LongTermDuration = 52,
  WorkPositionTemplate = 53,
  AgendaStatus = 54,
  LongTermEmployment = 55,
  LongTermRentalStatusClosure = 56,
  LongTermRentalFinancePartners = 57,
  ExternalKindOfBilling = 58,
  ModuleList = 59,
  UsersInAgenda = 60,
  PasswordExpiresAfter = 61,
  LongTermRentalHeaderType = 62,
  VehicleTypes = 63,
  BikeAndArticleBrand = 64,
  BikeAndArticleModel = 65,
  ProductLanguage = 95,
}

export enum ListHelperOperator {
  Contains = "contains",
  Not = "not",
  Equals = "equals",
  LessThan = "lt",
  LessEqual = "le",
  GreaterThan = "gt",
  GreaterEqual = "ge",
  In = "in",
}

export enum DataTableColumnFilter {
  Unknown = 0,
  Contains = 1,
  Equals = 2,
  Boolean = 3,
  CombinedSelect = 4,
  Complex = 5,
  Date = 6,
  Price = 7,
}

export enum Forms {
  BillinType = 1,
  Company = 2,
  Profile = 3,
  EmailSettings = 4,
  Accounting = 5,
  VehicleType = 6,
  VatManagement = 7,
  SubscriptionType = 8,
  Subscription = 9,
  QuestionCatalog = 10,
  Charging = 19,
  RentalObjekt = 11,
  RentalObjectItem = 12,
  RentalAttribute = 13,
  RentalPricing = 14,
  RentalSettings = 15,
  OpeninghourDay = 16,
  onBoardingCompany = 18,
  LongTermRent = 22,
  TimeTracking = 26,
}

export enum CycleModule {
  Undefined = 0,
  Giftcard = 1,
  Bonus = 2,
  Booking = 3,
  Event = 4,
  Orb = 5,
  Personalshopping = 6,
  Timemanagement = 7,
  Rental = 8,
}

export enum ImageSize {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export enum LoginType {
  Unknown = 0,
  User = 1,
  Contact = 2,
}
export enum RentObjectItemStatus {
  Undefined = "0",
  Rentable = "1",
  Repair = "2",
  Transfer = "3",
  Sold = "4",
  Stolen = "5",
  Defect = "6",
  Disposed = "7",
}

import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import de from "@/lang/de.json";
import moment from "moment";
import axios from "axios";

export function setupI18n() {
  const i18n = createI18n({
    locale: {
      value: "de"
    },
    fallbackLocale: "de",
    // legacy: false,
    // Global inject to use i18n like $t in template
    globalInjection: true,
    // default messages
    messages: { de },
  })
  setI18nLanguage(i18n, "de")
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  moment.locale(locale);
  axios.defaults.headers.common["Accept-Language"] = locale;
  document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/lang/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);
  setI18nLanguage(i18n, locale)

  return nextTick();
}

export async function addModuleLanguage (module, locale, i18n) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/lang/${module}/${locale}.json`
  );
  const current = i18n.global.getLocaleMessage(locale)
  current[module] = messages.default

  i18n.global.setLocaleMessage(locale, current);
  return nextTick(() => {return {key: module, lang: locale}});
}

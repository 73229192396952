import api from "@/config/Api.js";
import { loadLocaleMessages, addModuleLanguage } from "@/plugins/i18n";

const getDefaultState = () => {
  let modulesLoaded = {};
  let availableLanguages = [
    {
      langKey: "de",
      langIcon: require("@/assets/images/flags/germany_flag.jpg"),
      langName: "Deutsch",
      langShort: "DE",
    },
    {
      langKey: "en",
      langIcon: require("@/assets/images/flags/gb_flag.jpg"),
      langName: "English",
      langShort: "EN",
    },
    {
      langKey: "fr",
      langIcon: require("@/assets/images/flags/french_flag.jpg"),
      langName: "Français",
      langShort: "FR",
    },
    {
      langKey: "it",
      langIcon: require("@/assets/images/flags/italy_flag.jpg"),
      langName: "Italiano",
      langShort: "IT",
    },
    /*,
    {
      langKey: "fr",
      langIcon: require("@/assets/images/flags/french_flag.jpg"),
      langName: "Français",
      langShort: "FR"
    },
    {
      langKey: "it",
      langIcon: require("@/assets/images/flags/italy_flag.jpg"),
      langName: "Italiano",
      langShort: "IT"
    }*/
  ];

  if (process.env.NODE_ENV !== "production") {
    availableLanguages.push({
      langKey: "te",
      langIcon: require("@/assets/images/flags/test_flag.jpg"),
      langName: "Test",
      langShort: "XX",
    });
  }
  return {
    availableLanguages,
    currentLanguage: localStorage.getItem("lang") ?? "de",
    modulesLoaded,
  };
};

export const state = getDefaultState();

export const getters = {
  currentLanguage(state) {
    return state.currentLanguage;
  },
};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  changeLanguage(
    { commit, state, dispatch },
    { language, updateRemote, i18n }
  ) {
    if (language) {
      let usedLanguage = null;
      if (state.availableLanguages.some((el) => el.langKey == language)) {
        usedLanguage = language;
        loadLocaleMessages(i18n, language).then(() => {
          commit("CHANGE_LANGUAGE", language);
        });
      } else {
        usedLanguage = "de";
        commit("CHANGE_LANGUAGE", usedLanguage);
      }
      // Update the user remote
      if (
        updateRemote &&
        usedLanguage &&
        usedLanguage != this.state.language.currentLanguage
      ) {
        return api
          .post("/user/settings/language", {
            lang: usedLanguage,
          })
          .then((res) => {
            // Reload the iframe after the user was refreshed
            dispatch("navigation/updateRouterKey", true, { root: true });
            if (res.data.data == true) {
              return true;
            }
            return false;
          })
          .catch((e) => {
            if (console) console.error(e);
          });
      }
    }
    return new Promise((resolve) => {
      resolve(false);
    });
  },
  loadModuleLanguage({ commit, state }, { module, i18n }) {
    return new Promise((resolve) => {
      if (typeof state.modulesLoaded[module] == "undefined") {
        addModuleLanguage(
          module,
          this.state.language.currentLanguage,
          i18n
        ).then(() => {
          commit("LOADED_MODULE_LANGUAGE", {
            key: module,
            lang: this.state.language.currentLanguage,
          });
          resolve(true);
        });
      } else {
        if (
          !state.modulesLoaded[module].includes(
            this.state.language.currentLanguage
          )
        ) {
          addModuleLanguage(
            module,
            this.state.language.currentLanguage,
            i18n
          ).then(() => {
            commit("LOADED_MODULE_LANGUAGE", {
              key: module,
              lang: this.state.language.currentLanguage,
            });
            resolve(true);
          });
        }
      }
      resolve(false);
    });
  },
  loadAllModulesInNewLanguage({ commit, state }, { language, i18n }) {
    return new Promise((resolve) => {
      const promises = [];
      if (Object.keys(state.modulesLoaded).length !== 0) {
        for (const [key, value] of Object.entries(state.modulesLoaded)) {
          if (!value.includes(language)) {
            promises.push(addModuleLanguage(key, language, i18n));
          }
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then((res) => {
          res.map((item) => {
            commit("LOADED_MODULE_LANGUAGE", item)
          });
          resolve(true)
        });
      }
    });
  },
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  CHANGE_LANGUAGE(state, language) {
    state.currentLanguage = language;
    localStorage.setItem("lang", language);
  },
  LOADED_MODULE_LANGUAGE(state, data) {
    state.modulesLoaded[data.key] = Array.from(new Set([
      ...(state.modulesLoaded[data.key] ?? []),
      data.lang,
    ]));
  },
};

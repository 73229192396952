export default [
  {
    path: "/longterm-rental",
    name: "longterm-rental",
    component: () => import("@/views/longterm-rental/default.vue"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "",
        name: "longterm-rental-overview",
        component: () => import("@/views/longterm-rental/overview.vue"),
        meta: {
          authRequired: true,
        },
      },{
        path: ":id",
        name: "longterm-rental-detail",
        component: () => import("@/views/longterm-rental/form.vue"),
        meta: {
          authRequired: true,
        },
      },
    ]
  }
]
